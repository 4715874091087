$content-padding: 1.5rem;

@import "includes/scss/theme.scss";

@import "~bootstrap/scss/bootstrap";
@import "includes/scss/global.scss";
@import "includes/scss/caroussel.scss";
@import "includes/scss/header.scss";
@import "includes/scss/showcase.scss";
@import "includes/scss/subShowcase.scss";
@import "includes/scss/footer.scss";

@import "includes/scss/topUtility.scss";
@import "includes/scss/utility.scss";
@import "includes/scss/subUtility.scss";

@import "includes/scss/topMain.scss";
@import "includes/scss/main.scss";
@import "includes/scss/subMain.scss";

@import "includes/scss/topFeature.scss";
@import "includes/scss/feature.scss";
@import "includes/scss/subFeature.scss";

@import "includes/scss/parallax.scss";
@import "includes/scss/osm.scss";
@import "includes/scss/lightbox.scss";

body {
    background-color: rgb(255, 255, 255);
}