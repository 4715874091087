#subMain{
    padding: ($content-padding * 2) $content-padding;
    background-color: $secondary !important;
    color:white;

    .card-horizontal-accueil{
        h3{
            text-align: center;
            margin-top: 4rem;
            margin-bottom: 2rem;
            font-size: 2.5rem;
        }
        .card{
            box-shadow: $box-shadow;
            margin-bottom: 2rem;
            .row{
                .col{
                    .card-body{
                        .card-title{
                            text-align: center;
                            i{
                                display: block;
                                margin: 1rem 0;
                                color: $secondary;
                            }
                        }
                    } 
                }
    
                .col.hover-zoom{
                    overflow: hidden;
                    img{
                        transform: scale(1) rotate(0);
                        filter: grayscale(0);
                        transition: all 1s;
                    }
                }
    
                .col.hover-zoom:hover{
                    cursor: pointer;
                    img{
                        transform: scale(1.2) rotate(-2deg);
                        filter: grayscale(90%);
                        transition: all 1s;
                    }
                }
    
                .col.hover-zoom.rotate-left:hover{
                    cursor: pointer;
                    img{
                        transform: scale(1.2) rotate(2deg);
                        filter: grayscale(90%);
                        transition: all 1s;
                    }
                }
            }
        }
    }

    .card-horizontal-accueil > div:nth-child(-n+2){
        padding-bottom: 4rem;
    }

    .card-accueil-1{
        padding: 3rem;
        h3{
            font-family: "Play", sans-serif;
            color: white;
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
        @include media-breakpoint-only(xs) {
            padding: 0;
        }
        @include media-breakpoint-only(sm) {
            padding: 0;
        }
        .row{
            .col{
                text-align: center;
                padding: 2rem;
                @include media-breakpoint-only(xs) {
                    padding: 0 0 2rem 0;
                }
                @include media-breakpoint-only(sm) {
                    padding: 0 0 2rem 0;
                }
                .card{
                    box-shadow: $box-shadow;
                    .card-body{
                        padding: 0;
                        margin-bottom: 1rem;
                        i{
                            display: block;
                            margin: 1rem 0;
                            color: $primary;
                        }
                        h5{
                            font-size: 1.5rem;
                            text-align: center;
                            margin-bottom: 1rem;
                            color: white;
                            padding: 0.75rem;
                            background-color: $primary;
                        }
                        .card-text{
                            padding: 0.75rem;
                            p{
                                font-size: 1.1rem;
                                color: black;
                            }
                        }
                    }
                }
            }
        }
    }

    .card-livraison-partenaire-reduction{
        margin-top: 5rem;
        margin-bottom: 5rem;
        > h3{
            margin-bottom: 2rem;
        }
        > .row{
            margin-bottom: 5rem;
        }
        .row{
            .col{
                .card{
                    box-shadow: $box-shadow;
                    .card-body{
                        padding: 3rem;
                        h3{
                            color: black;
                            border-bottom: 1px solid $primary;
                            i{
                                display: block;
                                color: $primary;
                            }
                        }
                        .card-title{
                            text-align: center;
                            font-weight: bold;
                            margin-bottom: 2rem;
                            padding: 0.5rem;
                        }
                        .card-text{
                            p{
                                color: black;
                            }
                        }
                    }
                }
            }
        }

    }

    .card-deratisation{
        margin-top: 3rem;
        margin-bottom: 3rem;
        h3{
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
        .row{
            padding: 2rem;
            @include media-breakpoint-only(xs) {
                padding: 0;
            }
            @include media-breakpoint-only(sm) {
                padding: 0;
            }
            .col{
                padding: 2rem;
                @include media-breakpoint-only(xs) {
                    padding: 0 0 2rem 0;
                }
                @include media-breakpoint-only(sm) {
                    padding: 0 0 2rem 0;
                }
                .card{
                    box-shadow: $box-shadow;
                    .card-body{
                        padding: 0;
                        .card-title{
                            padding: 0.75rem;
                            text-align: center;
                            color: white;
                        }
                        .card-text{
                            padding: 0.75rem;
                        }
                    }
                }
            }

            .col:first-child{
                .card-title{
                    background-color: $primary;
                }
            }

            .col:last-child{
                .card-title{
                    background-color: $secondary;
                }
            }

            .col:nth-child(2){
                .card-title{
                    color: black;
                    background-color: #87ae3b;
                }
            }
        }
    }

    .galerie{
        padding: 0.25rem 0 5rem 0;
        .col{
            padding: 0.75rem;
            a{
                box-shadow: $box-shadow;
                display: block;
                border: 1px solid $white;
                cursor: pointer;
            }
            .card-img-overlay{
                top: inherit;
                p.description{
                    padding: 0.5rem;
                    text-align: center;
                    background-color: rgba($color: #ffffff, $alpha: 0.40);
                }
            }
        }

        .col.hover-zoom-left, .col.hover-zoom-right{
            overflow: hidden;
            img{
                transform: scale(1) rotate(0);
                transition: all 1s;
            }
        }

        .col.hover-zoom-left:hover{
            cursor: pointer;
            img{
                animation: zoom-in-zoom-out-gray 0.6s ease-in-out alternate;
            }
        }

        .col.hover-zoom-right:hover{
            cursor: pointer;
            img{
                animation: gelatine 0.6s ease-in-out alternate;
            }
        }

        @keyframes zoom-in-zoom-out-gray {
            0% {
              transform: scale(1, 1);
              filter: grayscale(70%);
            }
            40% {
              transform: scale(1.2, 1.2);
              filter: grayscale(90%);
            }
            50% {
              transform: scale(0.9, 0.9);
              filter: grayscale(90%);
            }
            70% {
              transform: scale(1.1, 1.1);
              filter: grayscale(90%);
            }
            90% {
              transform: scale(0.95, 0.95);
              filter: grayscale(90%);
            }
            100% {
              transform: scale(1, 1);
              filter: grayscale(70%);
            }
        }

        @keyframes zoom-in-zoom-out-sepia {
            0% {
                transform: scale(1, 1);
                filter: sepia(70%);
              }
              40% {
                transform: scale(0.98, 0.98);
                filter: sepia(90%);
              }
              50% {
                transform: scale(1.1, 1.1);
                filter: sepia(85%);
              }
              70% {
                transform: scale(0.99, 0.99);
                filter: sepia(50%);
              }
              90% {
                transform: scale(1.2, 1.2);
                filter: sepia(90%);
              }
              100% {
                transform: scale(1, 1);
                filter: sepia(70%);
              }
        }

        @keyframes gelatine {
            from, to { transform: scale(1, 1); filter: grayscale(70%);}
            25% { transform: scale(0.9, 1.1); filter: grayscale(90%); }
            50% { transform: scale(1.1, 0.9); filter: grayscale(80%); }
            75% { transform: scale(0.95, 1.05); filter: grayscale(75%); }
          }
    }

    .galerie-partenaires{
        .card{
            border-radius: 0;
            .card-body{
                box-shadow: $box-shadow;
                p{
                    background-color: $primary;
                    color: white;
                }
                .overlay{
                    background-color: rgba(0,0,0,0.35);
                    opacity: 0;
                    transition:  all 0.5s;
                    a{
                        i{
                            font-size: 7rem;
                            color: rgba(255,255,255,0.35);
                        }
                    }
                }
            }
        }
        
        .card:hover{
            .overlay{
                opacity: 1;
                transition:  all 0.5s;
            }
        }
    }

    .slider-velos{
        padding: 5rem 0;
        h2{
            font-family: "Play", sans-serif;
            font-size: 1.5rem;
        }
        .tns-ovh{
            .tns-inner{
                margin: 0;
                .slider{
                    .item{
                        .card{
                            background: none;
                            border: 0;
                            .card-body{
                                a{
                                    box-shadow: $box-shadow;
                                    border: $border-white;
                                    display: block;
                                    cursor: pointer;
                                }
                                .no-modal{
                                    box-shadow: $box-shadow;
                                    border: $border-white;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .block-titre-nouveaute{
        box-shadow: $box-shadow;
        border: 1px solid white;
        padding: 2rem !important;
        background-color: $primary;
        margin-top: 2rem;
        margin-bottom: 3rem;
        margin-left: 3rem;
        margin-right: 4rem;
        h3{
            color: white;
            font-size: 2.5rem;
            i{
                display: block;
                font-size: 2.5rem;
            }
        }
        p{
            font-size: 1.5rem;
            color: white;
        }
    }
}

.frigos-tv-poussettes{
    #subMain{
        background-color: $primary !important;
    }
}

.partenaires{
    #subMain{
        background-color: $gray-400 !important;
    }
}

.feature-partenaires{
    margin-top: 8rem;
    .row{
        h2{
            text-align: center;
            margin-bottom: 3rem;
            color: black;
        }
        .col{
            p{
                text-align: center;
            }
        }
    }
}