#topFeature{
    padding: ($content-padding * 2) $content-padding;
    background-color:$gray-200;
    color: black;

    .row-urgence{
        margin-top: 3rem;
        margin-bottom: 3rem;
        h3{
            min-height: 2rem;
            font-size: 3rem;
            @include media-breakpoint-only(xs) {
                font-size: 1.5rem;
            }
            @include media-breakpoint-only(sm) {
                font-size: 1.5rem;
            }
            i{
                display: block;
                margin-bottom: 1rem;
                font-size: 4rem;
                color: $primary;
            }
        }
        p{
            font-size: 2.5rem;
            @include media-breakpoint-only(xs) {
                font-size: 2rem;
            }
            @include media-breakpoint-only(sm) {
                font-size: 2rem;
            }
            margin-top: 2rem;
            .tel{
                color: black;
            }

            a{
                color: $black;
            }
        }
    }

    .tarif{
        .col-title{
            h2{
                font-family: "Play", sans-serif;
            }
        }
        .col-entete{
            .row{
                background-color: $primary;
                color: white;
            }
        }
        .col-tarif{
            .row{
                border-bottom: 1px solid $gray-600;
            }
            .red{
                background-color: $primary;
                color: white;
            }
        }
        .col-tarif-mobile{
            .row{
                .col{
                    border-bottom: 1px solid $gray-600;
                }
            }
            .red{
                background-color: $primary;
                color: white;
            }
        }
    }

}