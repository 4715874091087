
 /* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */
  #showcase{

    .carousel {
      .bi-enter {
        display: block;
        -webkit-animation: pulse 2s infinite;
        animation: pulse 2s infinite;
        color: #ffffff;
        font-size: 2rem;
        font-weight: bold;
      }

      h2{
        top: 240px;
        font-family: 'Play', sans-serif;
        font-size: 2.5rem;
        text-shadow: #fff 2px 0 3px;
        color: $black;
        font-weight: 600;
        @include media-breakpoint-down(md) {
          font-size: 1.1rem;
          text-shadow: none;
        }
      }

      .carousel-item{
        .carousel-caption{
          bottom: 4rem;
          z-index: 10;
          -webkit-animation: move-down 2s;
          animation: move-down 2s;
          i{
            color: $orange;
            text-shadow: #000 2px 0 3px;
          }
        }
      }
      
    }

  }

  .logo{
    @include media-breakpoint-only(lg) {
      position: absolute;
      top: 100px;
      left: 0;
      z-index: 1030;
      width: 100%;
      img{
        width: 350px;
      }
    }
    @include media-breakpoint-only(xl) {
      position: absolute;
      left: 0;
      top: 100px;
      z-index: 1030;
      width: 100%;
      img{
        width: 350px;
      }
    }
    @include media-breakpoint-only(xxl) {
      position: absolute;
      left: 0;
      top: 100px;
      z-index: 1030;
      width: 100%;
      img{
        width: 350px;
      }
    }
  }
  
  @keyframes pulse {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
    50% {
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px); }
    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); } 
  }

  @-webkit-keyframes move-down {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
    50% {
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px); }
    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
  }
  
  @keyframes move-down {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
    50% {
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px); }
    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
  }
  
  
  /* MARKETING CONTENT
  -------------------------------------------------- */
  
  /* Center align the text within the three columns below the carousel */
  .marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .marketing h2 {
    font-weight: 400;
  }
  /* rtl:begin:ignore */
  .marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
  }
  /* rtl:end:ignore */
  
  
  /* Featurettes
  ------------------------- */
  
  .featurette-divider {
    margin: 5rem 0; /* Space out the Bootstrap <hr> more */
  }
  
  /* Thin out the marketing headings */
  .featurette-heading {
    font-weight: 300;
    line-height: 1;
    /* rtl:remove */
    letter-spacing: -.05rem;
  }
  
  
  /* RESPONSIVE CSS
  -------------------------------------------------- */
  
  @media (min-width: 40em) {
    /* Bump up size of carousel content */
    .carousel-caption p {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.4;
    }
  
    .featurette-heading {
      font-size: 50px;
    }
  }
  
  @media (min-width: 62em) {
    .featurette-heading {
      margin-top: 7rem;
    }
  }
  